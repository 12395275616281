import React from 'react'

function Projects({ pageContext }) {
  return (
  <div>
    <h1>{pageContext.title}</h1>
    <img style={{
      width: '20vw'
    }} srcSet={pageContext.featuredImage.srcSet} src={pageContext.featuredImage.link} />
    <div dangerouslySetInnerHTML={{__html: pageContext.description.description}} />
  </div>
  )
}

export default Projects;